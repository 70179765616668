<template>
  <!-- profile info  -->
  <section id="pengajuan">
    <b-row>
      <!-- about suggested page and twitter feed -->
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>
      <!--/ about suggested page and twitter feed -->

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-alert
          v-if="sipdFailed"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Found
          </h4>
          <div class="alert-body">
            <span
              v-if="sipdFailedMsg != ''"
            >
              Data Kegiatan tidak ditemukan di Data <u>Aplikasi Sakti</u>, hubungi <u>Admin</u> atau isi form pada
              <b-link
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSfRi98gWYJnGLThDh9AeCVRMhQQq5u_l4aCgKfWvlQ8pFf2ow/viewform"
              >link berikut</b-link>.
            </span>
            <span v-else>Konektivitas dengan <u>Sakti</u> gagal, Muat ulang Halaman untuk mencoba kembali atau hubungi <u>Admin</u></span>
          </div>
        </b-alert>

        <profile-table />
      </b-col>
      <!-- post -->

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import ProfileAbout from '../pengajuan/module/PengajuanAbout.vue'
import ProfileSideMenu from '../pengajuan/module/PengajuanSideMenu.vue'
import ProfileTable from '../pengajuan/module/TabelSuratDetailGroup.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    ProfileAbout,
    ProfileSideMenu,
    ProfileTable,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10 && role < 99 && role > 110) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      userData: getUserData(),
      sipdFailed: false,
      sipdFailedLink: false,
      sipdFailedMsg: '',
      saktiToken: '',
    }
  },
  created() {
    // this.splpSaktiGetToken()
  },
  methods: {
    splpSaktiGetToken() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.get(`/sakti/token?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            this.saktiToken = res.data.data.access_token
            this.saktiGetUnit()
          } else {
            this.sipdFailed = true
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.sipdFailedMsg = error
          this.sipdFailed = true
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
    saktiGetUnit() {
      this.$splp.get('/ega', {
        headers: {
          Authorization: `Bearer ${this.saktiToken}`,
        },
        params: {
          year: localStorage.getItem('tahunAnggaran'),
          table: 'unit',
          kddept: this.userData.instansi_kddept,
        },
      })
        .then(res => {
          if (res.data.success === true) {
            const pics = []
            res.data.data.map(value => {
              const pic = value
              pic.label = `${value.kddept}.${value.kdunit}. ${value.nmunit}`
              pics.push(pic)
              return true
            })
            if (pics.length === 0) {
              this.sipdFailed = true
              this.sipdFailedLink = true
              this.sipdFailedMsg = 'Data Kegiatan tidak ditemukan di <u>Sakti</u> gagal, hubungi <u>Admin</u> atau isi form pada link berikut.'
              document.getElementById('loading-bg').style.display = 'none'
            } else {
              document.getElementById('loading-bg').style.display = 'none'
            }
          } else {
            this.sipdFailed = true
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(error => {
          this.sipdFailedMsg = error
          this.sipdFailed = true
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
